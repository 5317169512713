const moment = require('moment');

const join = (rules) => (value, data) => rules.map(rule => rule(value, data)).filter(error => !!error)[0 /* first error */];

export const isEmpty = value => value === undefined || value === null || value === '';

export function isNotBeforeToday(value) {
    if (moment(value).isBefore(moment(), 'day')) {
        return `Date cannot be in the past`;
    }
}

export function email(value) {
    if (!isEmpty(value) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i.test(value)) {
        return 'Invalid email address';
    }
}

export function phone(value) {
    if (value && (value.length < 5 || !/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value))) {
        return 'Invalid phone number';
    }
}

export function required(value) {
    if (isEmpty(value)) {
        return 'Required';
    }
}

export function minLength(min) {
    return value => {
        if (!isEmpty(value) && value.length < min) {
            return `Must be at least ${min} characters`;
        }
    };
}

export function maxLength(max) {
    return value => {
        if (!isEmpty(value) && value.length > max) {
            return `Must be no more than ${max} characters`;
        }
    };
}

export function integer(value) {
    if (value && !Number.isInteger(Number(value))) {
        return 'Must be a number';
    }
    if (value && !Number.isSafeInteger(Number(value))) {
        return `Must be smaller than ${Number.MAX_SAFE_INTEGER || '9007199254740992'}`;
    }
}

export function oneOf(enumeration) {
    return value => {
        if (!~enumeration.indexOf(value)) {
            return `Must be one of: ${enumeration.join(', ')}`;
        }
    };
}

export function match(field) {
    return (value, data) => {
        if (data) {
            if (value !== data[field]) {
                return 'Do not match';
            }
        }
    };
}

export function isURL(value) {
    if (!isEmpty(value) && !/^https?:\/\//.test(value)) {
        return "URL must begin with 'http' or 'https'";
    }
}

export const isSecureURL = (value) => {
    if (!isEmpty(value) && !/^https:\/\//.test(value)) {
        return "URL must begin with 'https'";
    }
}

export function isIP(value) {
    if (!isEmpty(value) && !/^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/.test(value)) {
        return "Must be a valid IPv4 address";
    }
}

export const normalizeInteger = value => value && parseInt(value);
export const normalizeNumber = value => value && Number(value);
export const normalizeEmail = value => value && `${value}`.trim().toLowerCase();
export const normalizeLowerCase = normalizeEmail;

export function createValidator(rules) {
    return (data = {}) => {
        const errors = {};
        Object.keys(rules).forEach((key) => {
            const rule = join([].concat(rules[key])); // concat enables both functions and arrays of functions
            const error = rule(data[key], data);
            if (error) {
                errors[key] = error;
            }
        });
        return errors;
    };
}
